<template>
  <v-row class="d-flex justify-center">
    <v-col cols="12" sm="12" md="3">
      <card-multitarefas
        nome="Pátios"
        :placeholder="isPrepareEditPatio ? 'Editar pátio' : 'Novo Pátio...'"
        :items="patios"
        tipo="patio"
        @next="next"
        @deleteItem="openModalDelete"
        @save="criarPatio"
        @prepareEdit="prepareEditPatio"
        @edit="editarPatio"
        :editItem="editPatioPatio"
        :hasNext="true"
        :hasEdit="true"
        :hasSelecionado="true"
        :editable="true"
      />
    </v-col>

    <v-col cols="12" sm="12" md="3">
      <card-multitarefas
        nome="Terminais"
        placeholder="Novo terminal..."
        :items="terminaisFiltrados"
        tipo="terminal"
        @deleteItem="openModalDelete"
        @save="criarTerminal"
        @prepareEdit="prepareEditTerminal"
        @edit="editarTerminal"
        :editItem="editTerminalTerminal"
        :hasSelecionado="patioSelecionado.selecionado"
        :hasNext="false"
        :hasEdit="true"
        :editable="true"
      />
    </v-col>

    <v-dialog
      v-model="hasErrorDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <v-card-text >
            <h1 class="transition-swing text-h1 mb--1 d-flex justify-center w-100 error__delete_title">
              OPS!
            </h1>
            <p class="font-weight-medium d-flex justify-center mt-10 error__delete_text">
              Ocorreu um erro no processamento!
            </p>
            <p class="font-weight-medium d-flex justify-center error__delete_text">
              Verifique se não há registros vinculados a este {{errorName}}!
            </p>
          </v-card-text>
        </v-card-title>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="primary"
            text
            @click="hasErrorDelete = false; dialogDelete = false; dialogDeleteTipo = '';"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogEdit"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <v-card-text >
            <h3 class="transition-swing text-h3 mb--1">
              Editar
            </h3>
            
          </v-card-text>
        </v-card-title>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="primary"
            text
            @click="dialogEdit = false"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <v-card-text >
            <h3 class="transition-swing text-h3 mb--1 error--text">
              Cuidado!
            </h3>
            <p class="mt-8">
              Tem certeza que quer deletar esse {{ dialogDeleteTipo }}?
            </p>
          </v-card-text>
        </v-card-title>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="primary"
            text
            @click="dialogDelete = false; dialogDeleteTipo = '';"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="error"
            depressed
            @click="deleteItem"
          >
            Deletar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-row>
</template>

<style lang="scss">
  .error__delete_title {
    color: #ff5252;
  }
  .error__delete_text {
    color: #545454;
  }
</style>

<script>
import { mapState, mapActions } from 'vuex'

import CardMultitarefas from '../../CardMultitarefas/CardMultitarefas.vue'

export default {
  name: 'CardsTerminal',
  components: {
    CardMultitarefas
  },

  data () {
    return {
      patioSelecionado: {
        data: {},
        selecionado: false
      },
      terminalSelecionado: {
        data: {},
        selecionado: false
      },
      selecionadoDefault: {
        data: {},
        selecionado: false
      },
      ultimoPatioCriado: {},
      ultimoTerminalCriado: {},
      hasErrorDelete: false,
      errorName: 'pátio',
      dialogEdit: false,
      isPrepareEditPatio: false,
      editPatioPatio: {},
      isPrepareEditTerminal: false,
      editTerminalTerminal: {},
      dialogDelete: false,
      dialogDeleteTipo: '',
      dialogDeleteId: null
    }
  },

  computed: {
    ...mapState('patio', ['patios', 'terminais']),
    ...mapState('auth', ['empresaAtual']),

    terminaisFiltrados () {
      if (this.patioSelecionado.selecionado) {
        const terminal = this.terminais.filter(terminal => terminal.patio === this.patioSelecionado.data.id && terminal.proprietario === this.empresaAtual.id)
        return terminal
      } else {
        return []
      }
    },
  },

  created() {
    this.getPatio()
    this.getTerminal()

  },

  methods: {
    ...mapActions('patio', [
        'getPatio', 'getTerminal',
        'addPatio', 'addTerminal',
        'editPatio', 'editTerminal',
        'deletePatio', 'deleteTerminal'
    ]),

    next(item, tipo) {
      if (tipo === "patio") {
        if (this.patioSelecionado.data.id !== item.id) {
          Object.assign(this.terminalSelecionado, this.selecionadoDefault)
          this.editTerminalTerminal = {
            descricao: '',
            isNotEdit: true
          }
          this.isPrepareEditTerminal = false
        }
        this.patioSelecionado.selecionado = true
        this.patioSelecionado.data = item
      } else {
        this.terminalSelecionado.selecionado = true
        this.terminalSelecionado.data = item
      }
    },

    async openModalDelete (id, tipo) {
      switch (tipo) {
        case 'patio':
          this.dialogDelete = true
          this.dialogDeleteTipo = 'Pátio'
          this.dialogDeleteId = id
          break;
        case 'terminal':
          this.dialogDelete = true
          this.dialogDeleteTipo = 'Terminal'
          this.dialogDeleteId = id
          break;
      }
    },

    async deleteItem() {
      let result
      
      switch (this.dialogDeleteTipo) {
        case 'Pátio':
          result = await this.deletePatio(this.dialogDeleteId)
          break;
        case 'Terminal':
          result = await this.deleteTerminal(this.dialogDeleteId)
          break;
      }

      if (!result) {
        this.errorName = this.dialogDeleteTipo === 'patio' ? 'pátio' : this.dialogDeleteTipo
        this.hasErrorDelete = true
      } else {
        this.dialogDelete = false
        this.dialogDeleteId = null
        this.dialogDeleteTipo = ''
      }
    },

    async criarPatio (data) {
      data.proprietario = this.empresaAtual.id
      await this.addPatio(data)
      await this.getPatio(this.empresaAtual.id)
    },

    prepareEditPatio (patio) {
      this.isPrepareEditPatio = true
      this.editPatioPatio = patio
      this.next(patio, 'patio')
      // Object.assign(this.terminalSelecionado, this.selecionadoDefault)
    },

    async editarPatio (patio) {
      await this.editPatio(patio)
    },

    async criarTerminal (data) {
      data.proprietario = this.empresaAtual.id
      data.patio = this.patioSelecionado.data.id
      await this.addTerminal(data)
      await this.getTerminal(this.empresaAtual.id)
    },

    async prepareEditTerminal (terminal) {
      this.isPrepareEditTerminal = true
      this.editTerminalTerminal = terminal
    },

    async editarTerminal (item) {
      await this.editTerminal(item)
    },
  }
}
</script>